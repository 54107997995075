import {
  BEBetData,
  BECommentary,
  BECompetitionStatus,
  BECompetitor,
  BECompetitorIdentifier,
  BEEceArticle,
  BEEscenicArticle,
  BEEventTagKeyword,
  BELineupData,
  BEMatchBetData,
  BEMatchInfo,
  BEMatchLiveData,
  BEMatchResult,
  BEMatchSimple,
  BEMatchState,
  BEMatchStats,
  BEPeriodScore,
  BEPlayerType,
  BEProbability,
  BERoster,
  BESport,
  BEStanding,
  BETeamRanking,
  BETimeEvent,
  BETimelineUpdateCommand,
  BETVProgramEvent,
  BEUpdateCommandType,
} from './types';

import {
  BetData,
  BetProviderData,
  CompetitionName,
  CompetitionStatus,
  CompetitionTablesTeam,
  CompetitionTrend,
  EceArticle,
  EventStatus,
  LineupData,
  LineupPlayer,
  MatchBetData,
  MatchInfo,
  MatchLineups,
  MatchLiveData,
  MatchResult,
  MatchSimple,
  PeriodScore,
  Probabilities,
  Sport,
  StatData,
  StatDataGeneric,
  StatDataMultivalue,
  StatNameType,
  Team,
  TeamInfo,
  TeamName,
  TeamRankings,
  TeaserArticle,
  TimelineCommentators,
  TimelineUpdate,
  TimelineUpdateCommand,
  TimelineUpdateCommandType,
  TimelineUpdateType,
  TVProgramEvent,
} from '../utils/types';

import {Middleware} from './performQuery';

/**
 * Since the majority of the response from the GraphQL are wrapped in a nested object, the createMiddleware utility
 * help to create a function that receive the AxiosResponse object and returns the relevant data only.
 *
 * Usage:
 *
 * ```
 * const myMiddleware = createMiddleware('nested');
 *
 * const data = myMiddleware(response); // returns response.data.data.nested;
 * ```
 */
export const createMiddleware =
  (prop: string): Middleware =>
  response =>
    response.data.data[prop];

// BIQ: check for undefined BE properties in this file (e.g. probabilities, versus, beMld)

/**
 * This file contains conversion utilities that map backend returned object to
 * frontend data representation that will be stored in the application state
 */
const beSportToSport = (beS: BESport): Sport => {
  switch (beS) {
    case 'soccer':
      return Sport.SOCCER;
    case 'basketball':
      return Sport.BASKETBALL;
    case 'volleyball':
      return Sport.VOLLEYBALL;
    case 'tennis':
      return Sport.TENNIS;
    case 'handball':
      return Sport.HANDBALL;
    default:
      return Sport.UNDEFINED;
  }
};

const sportToBeSport = (s: Sport): BESport => {
  switch (s) {
    case Sport.UNDEFINED:
      return BESport.EMPTY;
    case Sport.SOCCER:
      return BESport.SOCCER;
    case Sport.BASKETBALL:
      return BESport.BASKETBALL;
    case Sport.VOLLEYBALL:
      return BESport.VOLLEYBALL;
    case Sport.TENNIS:
      return BESport.TENNIS;
    case Sport.HANDBALL:
      return BESport.HANDBALL;
    default:
      return BESport.EMPTY;
  }
};

const beMatchStateToEventStatus = (beS: BEMatchState): EventStatus => {
  switch (beS) {
    case BEMatchState.NOT_STARTED:
      return EventStatus.NOT_STARTED;
    case BEMatchState.LIVE:
    case BEMatchState.MATCH_ABOUT_TO_START:
      return EventStatus.LIVE;
    case BEMatchState.CLOSED:
    case BEMatchState.ENDED:
      return EventStatus.CLOSED;
    default:
      return EventStatus.UNDEFINED;
  }
};

const beCompetitorToTeamName = (beC: BECompetitor): TeamName => {
  return {
    fullname: beC.teamName,
    shortname: beC.teamAbbreviation,
  };
};

const beAbbreviationToTeamName = (abbr: string, beCs: BECompetitor[]): TeamName => {
  const team = beCs.find(beC => beC.teamAbbreviation === abbr);
  return {
    fullname: team?.teamName || '',
    shortname: team?.teamAbbreviation || abbr,
  };
};

const beCompetitorToFlag = (beC: BECompetitor): string => {
  return beC && beC.countryCode
    ? `https://matchcenter-stage.24media.gr/logos/flags/120/${beC.countryCode}.png`
    : '';
};

const beCompetitorToTeamInfo = (beC: BECompetitor): TeamInfo => {
  return {
    name: beC.teamName,
    shortName: beC.teamAbbreviation,
    imageUrl: beC.logoUrl ? beC.logoUrl : '',
  };
};

const bePeriodScoresToPeriodScores = (bePS: BEPeriodScore[], sport: Sport): PeriodScore[] => {
  const regularPeriods: BEPeriodScore[] = [];
  const extraPeriods: BEPeriodScore[] = [];

  bePS.forEach(ps => {
    if (
      ['regular_period', 'set'].includes(ps.periodType) &&
      [1, 2, 3, 4, 5].includes(ps.periodNumber)
    )
      regularPeriods.push(ps);
    else extraPeriods.push(ps);
  });

  const periodScores = new Array(5).fill(null).map((v, i) => {
    const reIndex = i + 1;

    const bePeriodScore = regularPeriods.find(ps => ps.periodNumber === reIndex);

    const periodNumber = reIndex === 5 && sport === Sport.BASKETBALL ? 'Π' : `${reIndex}`;
    let homeScore = bePeriodScore?.homeScore;
    let awayScore = bePeriodScore?.awayScore;

    if (reIndex === 5 && extraPeriods.length) {
      const sumHome = extraPeriods.reduce((sum, ps) => sum + ps.homeScore, 0);
      const sumAway = extraPeriods.reduce((sum, ps) => sum + ps.awayScore, 0);

      homeScore = homeScore ? homeScore + sumHome : homeScore;
      awayScore = awayScore ? awayScore + sumAway : awayScore;
    }

    return {periodNumber, homeScore, awayScore};
  });

  return periodScores;
};

const beCommentaryToTimelineCommentators = (beC: BECommentary): TimelineCommentators => {
  return {
    reporters: (beC?.reporters || []).map(({fullname}) => fullname),
    commentators: (beC?.commentators || []).map(({fullname}) => fullname),
  };
};

const beProbabilitiesToProbabilities = (
  homeTeamName: string,
  awayTeamName: string,
  beP: BEProbability[]
): Probabilities => {
  const probs: any = {};

  beP.forEach(p => {
    switch (p.name) {
      case 'home_team_winner':
        probs.home = {
          name: homeTeamName,
          probability: p.outcome.toFixed(0),
        };
        break;
      case 'away_team_winner':
        probs.away = {
          name: awayTeamName,
          probability: p.outcome.toFixed(0),
        };
        break;
      default:
    }
  });

  return <Probabilities>{
    home: probs.home,
    away: probs.away,
  };
};

const beTeamRankingsToTeamRankings = (
  beTRs: BETeamRanking[],
  beCs: BECompetitor[]
): TeamRankings | undefined => {
  const identifiers: Exclude<Team, Team.UNDEFINED>[] = [Team.HOME, Team.AWAY];
  const teamRankings: Partial<TeamRankings> = {
    association: '',
    home: undefined,
    away: undefined,
  };

  beCs.forEach((beC, i) => {
    if (i > 1) return;

    const team = beTRs.find(beTR => beTR.competitorId === beC.sportRadarCompetitorId);
    if (!team) return;

    if (!teamRankings.association) teamRankings.association = team.associationName;

    teamRankings[identifiers[i]] = {
      name: beC.teamName,
      country: beC.teamCountry,
      flag: beCompetitorToFlag(beC),
      rank: team.rank,
      points: team.points,
    };
  });

  if (!teamRankings.home || !teamRankings.away) return undefined;

  return <TeamRankings>teamRankings;
};

const beMatchSimpleToMatchSimple = (beMs: BEMatchSimple): MatchSimple => {
  const {
    _id,
    sportRadarEventId,
    sportEventSlug,
    season,
    seasonCompetitionId,
    competitionGroup,
    competitionGroupId,
    startDate,
    status,
    matchStatus,
    homeScore,
    awayScore,
    competitors,
    periodScores,
    minute,
    stoppageTime,
  } = beMs;

  const sport = beSportToSport(season.competition.sportType);
  const slug = sportEventSlug || 'match';

  return {
    _id,
    matchStatus,
    matchId: sportRadarEventId,
    sportEventSlug: slug,
    sport,
    competition: season.competition.name,
    competitionId: seasonCompetitionId.replace(/:/g, '_'),
    grouping: competitionGroup,
    groupingId:
      competitionGroupId && competitionGroup
        ? competitionGroupId.replace(/:/g, '_')
        : seasonCompetitionId.replace(/:/g, '_'),
    date: new Date(startDate),
    status: beMatchStateToEventStatus(status),
    minute,
    stoppageTime,
    score: [homeScore, awayScore],
    periodScores: bePeriodScoresToPeriodScores(periodScores, sport),
    teams: [beCompetitorToTeamInfo(competitors[0]), beCompetitorToTeamInfo(competitors[1])],
    flags: [beCompetitorToFlag(competitors[0]), beCompetitorToFlag(competitors[1])],
  };
};

const beMatchResultToMatchResult = (beR: BEMatchResult): MatchResult => {
  return {
    score: beR.score,
    teams: [{fullname: beR.teamName, shortname: beR.teamAbbreviation}],
    result: beR.result,
  };
};

const beEceArticleToEceArticle = (
  beea: BEEceArticle | null | undefined
): EceArticle | undefined => {
  // TODO: reenable
  // if (beea === undefined || beea === null) return undefined;
  return undefined;
  /*
  const {
    eceId,
    title,
    summary,
    content,
    authorName,
    publishedDate,
    articleSection,
    articleImage,
  } = beea;

  const article: EceArticle = {
    eceId,
    section: {
      slug: articleSection.term,
      name: articleSection.label,
    },
    image: {
      url: articleImage.url, // BIQ: fallback
    },
    author: authorName,
    title,
    leadtext: summary,
    content,
    publishedDate: new Date(publishedDate),
  };

  return article;
  */
};

const beMatchInfoToMatchInfo = (beMi: BEMatchInfo): MatchInfo => {
  const {
    sportRadarEventId,
    season,
    competitionGroup,
    competitionGroupId,
    startDate,
    stadium,
    competitors,
    probabilities,
    teamRankings,
    versus,
    latestForms,
    commentary,
    eceArticleContent,
  } = beMi;

  const sport = beSportToSport(season.competition.sportType);
  const latestFormHome =
    latestForms && latestForms.find(r => r.qualifier === competitors[0].sportRadarCompetitorId);
  const latestFormAway =
    latestForms && latestForms.find(r => r.qualifier === competitors[1].sportRadarCompetitorId);

  return {
    srId: sportRadarEventId,
    sport,
    competition: season.competition.name as CompetitionName,
    seasonId: season.seasonId,
    grouping: competitionGroup,
    groupId: competitionGroupId,
    date: new Date(startDate),
    commentary: commentary ? beCommentaryToTimelineCommentators(commentary) : undefined,
    location: stadium,
    eceArticleContent: beEceArticleToEceArticle(eceArticleContent),
    teamRankings:
      teamRankings && sport === Sport.TENNIS
        ? beTeamRankingsToTeamRankings(teamRankings, competitors)
        : undefined,
    probabilities: probabilities
      ? beProbabilitiesToProbabilities(
          competitors[0].teamAbbreviation,
          competitors[1].teamAbbreviation,
          probabilities
        )
      : undefined,
    teams: {
      home: beCompetitorToTeamInfo(competitors[0]),
      away: beCompetitorToTeamInfo(competitors[1]),
    },
    formaHome: latestFormHome
      ? latestFormHome.sportEventResultList.map(r => {
          return beMatchResultToMatchResult(r);
        })
      : undefined,
    formaAway: latestFormAway
      ? latestFormAway.sportEventResultList.map(r => {
          return beMatchResultToMatchResult(r);
        })
      : undefined,
  };
};

const beMatchLiveDataToMatchLiveData = (beMld: BEMatchLiveData): MatchLiveData => {
  // FIXME: move commentary to 1m polling
  if (!beMld) {
    return {
      _id: 'mocked',
      sport: Sport.SOCCER,
      scores: {
        home: 0,
        away: 0,
      },
      status: 'not_started',
      minute: 0,
      stoppageTime: 0,
      matchStatus: '',
      commentary: {reporters: [], commentators: []},
      periodScores: [],
    };
  }

  const {
    _id,
    homeScore,
    awayScore,
    sportType,
    status,
    matchStatus,
    minute,
    stoppageTime,
    liveBroadcast,
    commentary,
    periodScores,
  } = beMld;
  const sport = beSportToSport(sportType);

  return {
    _id,
    sport,
    scores: {
      home: homeScore,
      away: awayScore,
    },
    commentary: beCommentaryToTimelineCommentators(commentary),
    periodScores: bePeriodScoresToPeriodScores(periodScores, sport),
    status: beMatchStateToEventStatus(status),
    matchStatus,
    minute,
    stoppageTime,
    broadcastUrl: liveBroadcast,
  };
};

const beUpdateCommandTypeToTimelineUpdateCommandType = (
  beC: BEUpdateCommandType
): TimelineUpdateCommandType => {
  switch (beC) {
    case 'UPDATE':
      return TimelineUpdateCommandType.UPDATE;
    case 'DELETE':
      return TimelineUpdateCommandType.DELETE;
    case 'ADD':
      return TimelineUpdateCommandType.ADD;
    default:
      return TimelineUpdateCommandType.UNDEFINED;
  }
};

const beEventTagKeywordToTimelineUpdateType = (k: BEEventTagKeyword): TimelineUpdateType => {
  switch (k) {
    case BEEventTagKeyword.MATCH_STARTED:
      return TimelineUpdateType.MATCH_STARTED;
    case BEEventTagKeyword.MATCH_ENDED:
      return TimelineUpdateType.MATCH_ENDED;
    case BEEventTagKeyword.BREAK_START:
      return TimelineUpdateType.BREAK_START;
    case BEEventTagKeyword.PERIOD_START:
      return TimelineUpdateType.PERIOD_START;
    case BEEventTagKeyword.PERIOD_SCORE:
      return TimelineUpdateType.PERIOD_SCORE;
    case BEEventTagKeyword.PERIOD_ENDED:
      return TimelineUpdateType.PERIOD_ENDED;
    case BEEventTagKeyword.SCORE_CHANGE:
      return TimelineUpdateType.SCORE_CHANGE;
    case BEEventTagKeyword.VIDEO_ASSISTANT_REFEREE:
      return TimelineUpdateType.VIDEO_ASSISTANT_REFEREE;
    case BEEventTagKeyword.VIDEO_ASSISTANT_REFEREE_OVER:
      return TimelineUpdateType.VIDEO_ASSISTANT_REFEREE_OVER;
    case BEEventTagKeyword.SUBSTITUTION:
      return TimelineUpdateType.SUBSTITUTION;
    case BEEventTagKeyword.YELLOW_CARD:
      return TimelineUpdateType.YELLOW_CARD;
    case BEEventTagKeyword.RED_CARD:
      return TimelineUpdateType.RED_CARD;
    case BEEventTagKeyword.YELLOW_RED_CARD:
      return TimelineUpdateType.YELLOW_RED_CARD;
    case BEEventTagKeyword.PENALTY_SHOOTOUT:
      return TimelineUpdateType.PENALTY_SHOOTOUT;
    case BEEventTagKeyword.PENALTY_AWARDED:
      return TimelineUpdateType.PENALTY_AWARDED;
    case BEEventTagKeyword.PENALTY_MISSED:
      return TimelineUpdateType.PENALTY_MISSED;
    case BEEventTagKeyword.SHOT_ON_TARGET:
      return TimelineUpdateType.SHOT_ON_TARGET;
    case BEEventTagKeyword.SHOT_OFF_TARGET:
      return TimelineUpdateType.SHOT_OFF_TARGET;
    case BEEventTagKeyword.INJURY:
      return TimelineUpdateType.INJURY;
    case BEEventTagKeyword.INJURY_RETURN:
      return TimelineUpdateType.INJURY_RETURN;
    case BEEventTagKeyword.INJURY_TIME_SHOWN:
      return TimelineUpdateType.INJURY_TIME_SHOWN;
    case BEEventTagKeyword.OFFSIDE:
      return TimelineUpdateType.OFFSIDE;
    case BEEventTagKeyword.CORNER_KICK:
      return TimelineUpdateType.CORNER_KICK;
    case BEEventTagKeyword.THROW_IN:
      return TimelineUpdateType.THROW_IN;
    case BEEventTagKeyword.FREE_KICK:
      return TimelineUpdateType.FREE_KICK;
    case BEEventTagKeyword.GOAL_KICK:
      return TimelineUpdateType.GOAL_KICK;
    case BEEventTagKeyword.SHOT_SAVED:
      return TimelineUpdateType.SHOT_SAVED;
    case BEEventTagKeyword.STEAL:
      return TimelineUpdateType.STEAL;
    case BEEventTagKeyword.BALL_BLOCK:
      return TimelineUpdateType.BALL_BLOCK;
    case BEEventTagKeyword.REBOUND:
      return TimelineUpdateType.REBOUND;
    case BEEventTagKeyword.TURNOVER:
      return TimelineUpdateType.TURNOVER;
    case BEEventTagKeyword.FOUL:
      return TimelineUpdateType.FOUL;
    case BEEventTagKeyword.WON_JUMP_BALL:
      return TimelineUpdateType.WON_JUMP_BALL;
    case BEEventTagKeyword.FREE_THROWS_AWARDED:
      return TimelineUpdateType.FREE_THROWS_AWARDED;
    case BEEventTagKeyword.ATTEMPT_MISSED:
      return TimelineUpdateType.ATTEMPT_MISSED;
    case BEEventTagKeyword.VIDEO_REVIEW:
      return TimelineUpdateType.VIDEO_REVIEW;
    case BEEventTagKeyword.TIMEOUT:
      return TimelineUpdateType.TIMEOUT;
    case BEEventTagKeyword.TIMEOUT_OVER:
      return TimelineUpdateType.TIMEOUT_OVER;
    case BEEventTagKeyword.POSTPONED:
      return TimelineUpdateType.POSTPONED;
    case BEEventTagKeyword.POINT:
      return TimelineUpdateType.POINT;
    case BEEventTagKeyword.SUSPENSION:
      return TimelineUpdateType.SUSPENSION;
    case BEEventTagKeyword.SEVEN_M_AWARDED:
      return TimelineUpdateType.SEVEN_M_AWARDED;
    case BEEventTagKeyword.SEVEN_M_MISSED:
      return TimelineUpdateType.SEVEN_M_MISSED;
    case BEEventTagKeyword.OPEN_TEXT:
      return TimelineUpdateType.OPEN_TEXT;
    case BEEventTagKeyword.EDITOR_INFO:
      return TimelineUpdateType.EDITOR_INFO;
    case BEEventTagKeyword.EDITOR_HIGHLIGHT:
      return TimelineUpdateType.EDITOR_HIGHLIGHT;
    case BEEventTagKeyword.EXPERT_COMMENT:
      return TimelineUpdateType.EXPERT_COMMENT;
    case BEEventTagKeyword.PHOTO_HIGHLIGHT:
      return TimelineUpdateType.PHOTO_HIGHLIGHT;
    case BEEventTagKeyword.VIDEO_HIGHLIGHT:
      return TimelineUpdateType.VIDEO_HIGHLIGHT;
    case BEEventTagKeyword.SOCIAL:
      return TimelineUpdateType.SOCIAL;
    case BEEventTagKeyword.SPONSORED:
      return TimelineUpdateType.SPONSORED;
    case BEEventTagKeyword.OPTA_DATA:
      return TimelineUpdateType.OPTA_DATA;
    case BEEventTagKeyword.GOOD_OPPORTUNITY:
      return TimelineUpdateType.GOOD_OPPORTUNITY;
    case BEEventTagKeyword.GOOD_MOMENT:
      return TimelineUpdateType.GOOD_MOMENT;
    case BEEventTagKeyword.HISTORIC_FACT:
      return TimelineUpdateType.HISTORIC_FACT;
    case BEEventTagKeyword.VIOLENT_INCIDENT:
      return TimelineUpdateType.VIOLENT_INCIDENT;
    case BEEventTagKeyword.VAR:
      return TimelineUpdateType.VAR;
    case BEEventTagKeyword.GOALPOST:
      return TimelineUpdateType.GOALPOST;
    case BEEventTagKeyword.BUZZER_BEATER:
      return TimelineUpdateType.BUZZER_BEATER;
    case BEEventTagKeyword.FIVE_OMADIKO_FOUL:
      return TimelineUpdateType.FIVE_OMADIKO_FAUL;
    case BEEventTagKeyword.ON_FIRE:
      return TimelineUpdateType.ON_FIRE;
    case BEEventTagKeyword.REFEREE:
      return TimelineUpdateType.REFEREE;
    case BEEventTagKeyword.TECHNICAL_FOUL:
      return TimelineUpdateType.TECHNICAL_FOUL;
    case BEEventTagKeyword.DISQUALIFICATION:
      return TimelineUpdateType.DISQUALIFICATION;
    case BEEventTagKeyword.TECHNICAL_PENALTY:
      return TimelineUpdateType.TECHNICAL_PENALTY;
    case BEEventTagKeyword.ACE_VOLLEYBALL:
      return TimelineUpdateType.ACE_VOLLEYBALL;
    case BEEventTagKeyword.BLOCK_VOLLEYBALL:
      return TimelineUpdateType.BLOCK_VOLLEYBALL;
    case BEEventTagKeyword.VAR_VOLLEYBALL:
      return TimelineUpdateType.VAR_VOLLEYBALL;
    case BEEventTagKeyword.TIMEOUT_VOLLEYBALL:
      return TimelineUpdateType.TIMEOUT_VOLLEYBALL;
    case BEEventTagKeyword.VIDEO_CHALLENGE:
      return TimelineUpdateType.VIDEO_CHALLENGE;
    case BEEventTagKeyword.TIMEOUT_HANDBALL:
      return TimelineUpdateType.TIMEOUT_HANDBALL;
    case BEEventTagKeyword.AUTOGOAL:
      return TimelineUpdateType.AUTOGOAL;
    default:
      return TimelineUpdateType.UNDEFINED;
  }
};

const beCompetitorIdentifierToTeam = (c: BECompetitorIdentifier): Team => {
  switch (c) {
    case BECompetitorIdentifier.HOME:
      return Team.HOME;
    case BECompetitorIdentifier.AWAY:
      return Team.AWAY;
    default:
      return Team.UNDEFINED;
  }
};

const beTimeEventToTimelineUpdate = (e: BETimeEvent): TimelineUpdate | null => {
  const {
    _id,
    timeLineEventId,
    localDateTime,
    matchTime,
    eventTag,
    competitorIdentifier,
    openTitle,
    homeScore,
    awayScore,
    players,
    stoppageTime,
    stoppageTimeShown,
    user,
    method,
    periodName,
    result,
    points,
    content,
    pictureUrl,
    pictureCaption,
    embed,
    relatedVideo,
    sponsoredLink,
  } = e;

  if (typeof eventTag === 'undefined' || eventTag === null) return null;

  const update: TimelineUpdate = {
    id: timeLineEventId || _id,
    time: localDateTime,
    minute: matchTime ? `${matchTime}` : '',
    stoppageTime: stoppageTime ? `${stoppageTime}` : '',
    type: beEventTagKeywordToTimelineUpdateType(eventTag.keyword),
    teamType: beCompetitorIdentifierToTeam(competitorIdentifier),
    playerIds: [],
    method,
    // BIQ: teamName: infer from match detail + competitorIdentifier
    title: periodName || openTitle || eventTag.displayName,
    comment: content,
  };

  // TODO: change string cases below to BETimeEvent enum
  switch (eventTag.keyword) {
    case 'score_change':
    case 'point':
      if (method === 'ace' || method === 'double_fault') update.title = result;
      // ScoreContent
      if (method === 'own_goal') update.title = result;
      if (points) update.title = `${points}${update.title}`;
      // eslint-disable-next-line no-case-declarations
      let scorer = '';
      if (players && players.length >= 1) {
        scorer = players[0].name;
        update.playerIds.push(players[0].sportRadarPlayerId);
      }
      update.content = {
        score: {
          home: homeScore,
          away: awayScore,
        },
        scorer,
        type: method === 'own_goal' ? method : null,
      };
      break;
    case 'yellow_card':
    case 'red_card':
    case 'yellow_red_card':
      update.content = {
        player: players && players[0] ? players[0].name : '',
      };
      break;
    case 'shot_on_target':
    case 'shot_off_target':
    case 'penalty_awarded':
    case 'penalty_missed':
    case 'injury':
    case 'injury_return':
      if (players && players[0]) {
        update.content = players[0].name;
        update.playerIds.push(players[0].sportRadarPlayerId);
      }
      break;
    case 'injury_time_shown':
      if (stoppageTimeShown) {
        update.content = stoppageTimeShown.toString();
      }
      break;
    case 'substitution':
      // eslint-disable-next-line no-case-declarations
      let playerOut = '';
      if (players && players.length >= 1) {
        playerOut = players[0].name;
        update.playerIds.push(players[0].sportRadarPlayerId);
      }
      // eslint-disable-next-line no-case-declarations
      let playerIn = '';
      if (players && players.length >= 2) {
        playerIn = players[1].name;
        update.playerIds.push(players[1].sportRadarPlayerId);
      }
      // SwitchContent
      update.content = {
        players: {
          out: playerOut,
          in: playerIn,
        },
      };
      break;
    case 'break_start':
    case 'period_score':
    case 'period_ended':
    case 'match_ended':
      // StatusContent
      update.content = {
        statusType: eventTag.displayName,
        score:
          homeScore !== undefined && awayScore !== undefined
            ? {
                home: homeScore,
                away: awayScore,
              }
            : undefined,
      };
      break;
    case 'video_assistant_referee':
      update.title = `${update.title} ${result}`;
      break;
    case 'video_assistant_referee_over':
      update.content = result;
      break;
    case 'expert_comment':
      // EditorSignedContent
      if (user) {
        update.title = user.fullname;
        update.content = {
          editorImageUrl: user.logoUrl,
        };
      }
      break;
    case 'photo_highlight':
      // EditorPhotoContent
      update.title = 'PHOTO HIGHLIGHT';
      update.content = {
        photoUrl: pictureUrl,
        photoCaption: pictureCaption,
      };
      break;
    case 'video_highlight':
    case 'social':
    case 'opta_data':
      // EditorEmbedContent
      update.content = {
        embed,
      };
      if (relatedVideo !== undefined) update.relatedVideo = relatedVideo;
      break;
    case 'sponsored':
      if (embed) {
        update.content = {
          embed,
        };
      } else if (pictureUrl) {
        update.content = {
          photoUrl: pictureUrl,
          photoCaption: pictureCaption,
        };
      } else {
        update.content = content;
      }
      if (sponsoredLink) update.sponsoredLink = sponsoredLink;
      break;
    default:
    // content can be left undefined in unhandled cases
    // update.content = openTitle || eventTag.displayName;
  }

  return update;
};

const beTimelineUpdateCommandToTimelineUpdateCommand = (
  beTc: BETimelineUpdateCommand
): TimelineUpdateCommand | null => {
  const {_id, commandType, sportEventId, timeEvent} = beTc;
  const update = beTimeEventToTimelineUpdate(timeEvent);

  if (update === null) return null;
  return {
    sportEventId,
    update,
    id: _id,
    commandType: beUpdateCommandTypeToTimelineUpdateCommandType(commandType),
  };
};

// const beLiveMatchScoreToMatchLiveData = (beData: BEMatchLiveData): MatchLiveData => {
//   const {
//     _id,
//     status,
//     matchStatus,
//     minute,
//     stoppageTime,
//     sportType,
//     homeScore,
//     awayScore,
//     liveBroadcast,
//     periodScores,
//   } = beData;
//   const sport = beSportToSport(sportType);
//
//   return {
//     _id,
//     status,
//     matchStatus,
//     minute,
//     stoppageTime,
//     sport,
//     broadcastUrl: liveBroadcast,
//     scores: {
//       home: homeScore,
//       away: awayScore,
//     },
//     periodScores: bePeriodScoresToPeriodScores(periodScores, sport),
//   };
// };

const beMatchStatsToStatsData = (beData: BEMatchStats): StatData[] => {
  const {statistics} = beData;

  if (!statistics) return [];

  /* it is used for, given a statName, retrieving its statType */
  const statsDictionary: Record<StatNameType, Pick<StatData, 'name' | 'type'>> =
    Object.create(null);
  // Soccer
  statsDictionary.ballPossession = {name: 'ΚΑΤΟΧΗ', type: 'percentage'};
  statsDictionary.shotsTotal = {name: 'ΣΟΥΤ', type: 'number'};
  statsDictionary.shotsOnTarget = {name: 'ΣΟΥΤ ΣΤΗΝ ΕΣΤΙΑ', type: 'number'};
  statsDictionary.cornerKicks = {name: 'ΚΟΡΝΕΡ', type: 'number'};
  statsDictionary.fouls = {name: 'ΦΑΟΥΛ', type: 'number'};
  statsDictionary.offsides = {name: 'OFFSIDES', type: 'number'};
  // Basketball
  statsDictionary.threePointAttempts = {name: '3 ΠΟΝΤΟΙ', type: 'tuple'};
  statsDictionary.twoPointAttempts = {name: '2 ΠΟΝΤΟΙ', type: 'tuple'};
  statsDictionary.freeThrowAttempts = {name: 'ΕΛΕΥΘΕΡΕΣ ΒΟΛΕΣ', type: 'tuple'};
  statsDictionary.rebounds = {name: 'ΡΙΜΠΑΟΥΝΤ', type: 'number'};
  // fouls from key shared with soccer
  statsDictionary.teamTurnovers = {name: 'ΛΑΘΗ', type: 'number'};
  statsDictionary.steals = {name: 'ΚΛΕΨΙΜΑΤΑ', type: 'number'};
  statsDictionary.assists = {name: 'ΑΣΙΣΤ', type: 'number'};
  statsDictionary.shotsBlocked = {name: 'ΚΟΨΙΜΑΤΑ', type: 'number'};
  statsDictionary.biggestLead = {name: 'ΜΕΓΑΛΥΤΕΡΟ ΠΡΟΒΑΔΙΣΜΑ', type: 'number'};
  // Volleyball: no stats
  // Tennis
  statsDictionary.breakpointsWon = {name: 'BREAK POINT CONVERSION', type: 'percentage'};
  statsDictionary.aces = {name: 'ΑΣΣΟΙ', type: 'number'};
  statsDictionary.doubleFaults = {name: 'ΔΙΠΛΑ ΛΑΘΗ', type: 'number'};
  // Handball
  statsDictionary.sevenmGoals = {name: '7M THROW SUCCESS', type: 'number'};
  statsDictionary.shootingAccuracy = {name: 'ΕΥΣΤΟΧΙΑ', type: 'number'};
  // FIXME: shoots, saves, blocks
  statsDictionary.suspensions = {name: '2 ΛΕΠΤΑ - ΑΠΟΒΟΛΗ', type: 'number'};

  /**
   * Dynamically get keys with tuples, to avoid missing to update this list by hand in future changes.
   */
  const tupleKeys = Object.entries(statsDictionary)
    .filter(([, s]) => s.type === 'tuple')
    .map(([k]) => k);

  /**
   * For each statistic a new object with both home and away values is added to statByType
   */
  const statsData: Record<StatNameType, StatData> = Object.create(null);
  for (let i = 0; i < statistics.length; i += 1) {
    const curStats = statistics[i];
    const {qualifier} = curStats;
    if (typeof qualifier !== Team.UNDEFINED) {
      Object.entries(curStats).forEach(([key, value]) => {
        // BIQ: excess checks here? this has been done to avoid casting to number later
        if (
          key === 'qualifier' ||
          value === 'home' ||
          value === 'away' ||
          value === 'undefined' ||
          value === null ||
          typeof value === 'undefined'
        ) {
          return;
        }

        // cast variables to avoid constant re-casting
        const tKey = key as StatNameType;
        const tQualifier = qualifier as Exclude<Team, Team.UNDEFINED>;

        // handle keys that combine into tuples here
        const tupleKey = tupleKeys.find(k => key.startsWith(k)) as StatNameType | undefined;
        if (tupleKey) {
          // BIQ: simpler way to achieve this?
          if (typeof statsData[tupleKey] === 'undefined') {
            statsData[tupleKey] = Object.create(null);
          }
          if (!(qualifier in statsData[tupleKey])) {
            statsData[tupleKey][tQualifier] = [null, null];
          }
          const tupleIndex = key === `${tupleKey}Total` ? 1 : 0;
          const newTuple: [number | null, number | null] = [
            ...(statsData[tupleKey][tQualifier] as [number | null, number | null]),
          ];
          newTuple[tupleIndex] = value;
          statsData[tupleKey][tQualifier] = newTuple;
          statsData[tupleKey].name = statsDictionary[tupleKey].name;
          statsData[tupleKey].type = statsDictionary[tupleKey].type;
        } else {
          if (typeof statsData[tKey] === 'undefined') {
            statsData[tKey] = Object.create(null);
          }
          statsData[tKey][tQualifier] = value;
          statsData[tKey].name = statsDictionary[tKey].name;
          statsData[tKey].type = statsDictionary[tKey].type;
        }
      });
    }
  }

  const statsDataArray: StatData[] = Object.entries(statsData).map(
    ([key, {name, type, home, away}]) =>
      type === 'tuple'
        ? ({
            key,
            name,
            type,
            home,
            away,
          } as StatDataMultivalue)
        : ({
            key,
            name,
            type,
            home,
            away,
          } as StatDataGeneric)
  );

  return statsDataArray;
};

const beLineupPlayerToLineupPlayer = (beData: BEPlayerType): LineupPlayer => {
  const {shirt, area, name, sportRadarPlayerId} = beData;
  return {
    number: shirt,
    name,
    position: area,
    sportRadarPlayerId,
    events: [],
  };
};

const addPlayersToLineup = (lineup: LineupData, team: BERoster): void => {
  for (let j = 0; j < team.sportEventPlayerInfos.length; j += 1) {
    if (team.sportEventPlayerInfos[j].isStarter) {
      lineup.main.push(beLineupPlayerToLineupPlayer(team.sportEventPlayerInfos[j].player));
    } else {
      lineup.bench.push(beLineupPlayerToLineupPlayer(team.sportEventPlayerInfos[j].player));
    }
  }
};

const beLineupDataToMatchLineups = (beData: BELineupData): MatchLineups => {
  const {competitors, rosters} = beData;

  const home: LineupData = Object.create(null);
  home.main = [];
  home.bench = [];

  const away: LineupData = Object.create(null);
  away.main = [];
  away.bench = [];

  if (competitors && competitors.length > 0) {
    for (let i = 0; i < competitors.length; i += 1) {
      if (competitors[i].qualifier === Team.HOME) {
        home.teamName = competitors[i].teamName;
      } else if (competitors[i].qualifier === Team.AWAY) {
        away.teamName = competitors[i].teamName;
      }
    }
  }

  if (rosters && rosters.length > 0) {
    for (let i = 0; i < rosters.length; i += 1) {
      if (rosters[i].qualifier === Team.HOME) {
        addPlayersToLineup(home, rosters[i]);
      } else if (rosters[i].qualifier === Team.AWAY) {
        addPlayersToLineup(away, rosters[i]);
      }
    }
  }

  return {home, away};
};

const beCompetitionStatusToCompetitionStatus = (becs: BECompetitionStatus): CompetitionStatus => {
  switch (becs) {
    case BECompetitionStatus.PLAYOFFS:
      return 'playoffs';
    case BECompetitionStatus.PLAYOUTS:
      return 'playouts';
    default:
      return 'undefined';
  }
};

const beStandingToCompetitionTableTeams = (beS: BEStanding): CompetitionTablesTeam[] => {
  const {name, season, teamStandings} = beS;

  const tables = teamStandings.map(ts => {
    let _trend: CompetitionTrend;
    if (ts.positionChange > 0) {
      _trend = 'up';
    } else if (ts.positionChange < 0) {
      _trend = 'down';
    } else {
      _trend = null;
    }

    return {
      sportSeason: season.competition.sportType,
      teamName: ts.team.teamName,
      wins: ts.win,
      losses: ts.loss,
      ties: ts.draw,
      all: ts.played,
      points: ts.teamPoints,
      trend: _trend,
      pointsdiff: ts.pointsDiff,
      status: beCompetitionStatusToCompetitionStatus(ts.currentOutcome),
    };
  });

  return tables;
};

const beEscenicArticleToTeaserArticle = (beea: BEEscenicArticle): TeaserArticle => {
  const {id, alternateUrl, teasertitle, mainImage, tags, published, impressionurl} = beea;

  const article: TeaserArticle = {
    eceId: `${id}`,
    url: alternateUrl,
    image: {
      url: '', // BIQ: fallback
      caption: '',
    },
    title: teasertitle,
    publishedDate: new Date(published),
  };

  if (impressionurl) article.impressionUrl = impressionurl;

  if (mainImage && mainImage.length) {
    article.image = {
      url: mainImage[0].url,
      caption: mainImage[0].caption || teasertitle,
    };
  }

  if (tags && tags.length) {
    article.tag = {
      url: `/${tags[0].tag_title}`,
      displayName: tags[0].tag_desc,
    };
  }

  return article;
};

const beTVProgramEventToTVProgramEvent = (betpe: BETVProgramEvent): TVProgramEvent | null => {
  const {id, timeView, title, tags, channel} = betpe;

  // ignore sports other than soccer & basketball
  const sport = tags && tags.length > 0 ? tags[0].name : '';
  if (!['podosfairo', 'basket'].includes(sport)) return null;

  // ignore events outside of this time range
  const hoursNow = new Date().getHours();
  const parsedTime = timeView.match(/(?<hours>[0-9]{1,2}):(?<minutes>[0-9]{1,2})/);
  const eventHours = parsedTime?.groups?.hours && parseInt(parsedTime.groups.hours, 10);
  if (!eventHours || Number.isNaN(eventHours) || eventHours < hoursNow || eventHours > 23)
    return null;

  const event = {
    id: `${id}`,
    name: title,
    competition: tags && tags.length > 1 ? tags[1].displayName : '',
    time: timeView,
    channelId: `${channel.id}`,
  };

  return event;
};

const beMatchBetDataToMatchBetData = (beMbd: BEMatchBetData): MatchBetData => {
  const {_id, sportEventSlug, competitors, betMatch} = beMbd;

  const slug = sportEventSlug || 'match';

  return {
    _id,
    sportEventSlug: slug,
    teams: [beCompetitorToTeamInfo(competitors[0]), beCompetitorToTeamInfo(competitors[1])],
    betDataId: betMatch?._id || undefined,
  };
};

const beBetDataToBetData = (match: MatchBetData, bebd: BEBetData): BetData => {
  const {matchEventUrl, matchMarketList} = bebd;

  return {
    matchId: match._id,
    matchNames: [match.teams[0].name, match.teams[1].name],
    matchUrl: matchEventUrl ?? '',
    odds: matchMarketList
      .filter(({market}) => ['MRES', 'BTSC', 'HCTG'].includes(market.marketType))
      .map(({matchMarketId, market, selectionList}) => ({
        id: matchMarketId,
        type: market.marketType,
        title: market.marketName,
        values: selectionList.map(selection => ({
          id: selection.selectionId,
          label: selection.selectionName,
          value: selection.selectionPrice,
        })),
      })),
  };
};

const beBetDataToBetProviderData = (bebd: BEBetData): BetProviderData => {
  const {
    competition: {provider},
  } = bebd;

  return {
    name: provider.name,
    imageUrl: provider.logo,
    clickUrl: provider.link,
  };
};

export {
  beSportToSport,
  sportToBeSport,
  beMatchStateToEventStatus,
  beCompetitorToTeamName,
  bePeriodScoresToPeriodScores,
  beMatchSimpleToMatchSimple,
  beMatchInfoToMatchInfo,
  beMatchLiveDataToMatchLiveData,
  beTimeEventToTimelineUpdate,
  beTimelineUpdateCommandToTimelineUpdateCommand,
  // beLiveMatchScoreToMatchLiveData,
  beMatchStatsToStatsData,
  beLineupDataToMatchLineups,
  beStandingToCompetitionTableTeams,
  beEscenicArticleToTeaserArticle,
  beEceArticleToEceArticle,
  beTVProgramEventToTVProgramEvent,
  beMatchBetDataToMatchBetData,
  beBetDataToBetData,
  beBetDataToBetProviderData,
};
