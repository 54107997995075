import performQuery, {Middleware} from './performQuery';
import {
  BELineupData,
  BEMatchInfo,
  BEMatchLiveData,
  BEMatchSimple,
  BEMatchBetData,
  BEMatchStats,
  BESport,
} from './types';
import {createMiddleware} from './utils';
import validateBEMatchSimpleArray from './validation';

export const sportEventsByGroupId = (groupId: string): Promise<BEMatchSimple[]> => {
  const getRoot: Middleware = createMiddleware('sportEventsByGroupId');
  const query = `{
    sportEventsByGroupId(groupId: "${groupId}") {
      _id
      sportRadarEventId
      sportEventSlug
      sportType
      startDate
      seasonCompetitionId
      competitionGroup
      competitionGroupId
      status
      minute
      stoppageTime
      matchStatus
      homeScore
      awayScore
      season {
        name
        competition {
          name
          sportType
        }
      }
      competitors {
        logoUrl
        teamName
        teamAbbreviation
        countryCode
      }
      periodScores {
        homeScore
        awayScore
        periodType
        periodNumber
      }
    }
  }`;

  return performQuery(query, getRoot)();
};

export const sportEventsBetDataByGroupId = (groupId: string): Promise<BEMatchBetData[]> => {
  const getRoot: Middleware = createMiddleware('sportEventsByGroupId');
  const query = `{
    sportEventsByGroupId(groupId: "${groupId}") {
      _id
      sportEventSlug
      competitors {
        logoUrl
        teamName
        teamAbbreviation
        countryCode
      }
      betMatch {
        _id
      }
    }
  }`;

  return performQuery(query, getRoot)();
};

export const sportEventsByIds = (ids: string[]): Promise<BEMatchSimple[]> => {
  const getRoot: Middleware = createMiddleware('sportEventsByIds');
  const query = `{
    sportEventsByIds(ids: [${ids.map(i => `"${i}"`).join(',')}]) {
      _id
      sportRadarEventId
      sportEventSlug
      sportType
      startDate
      seasonCompetitionId
      competitionGroup
      competitionGroupId
      status
      minute
      stoppageTime
      matchStatus
      homeScore
      awayScore
      season {
        name
        competition {
          name
          sportType
        }
      }
      competitors {
        logoUrl
        teamName
        teamAbbreviation
        countryCode
      }
      periodScores {
        homeScore
        awayScore
        periodType
        periodNumber
      }
    }
  }`;

  return performQuery(query, getRoot)();
};

export default {
  sportEventsByGroupId,
  sportEventsBetDataByGroupId,
  sportEventsByIds,
};
